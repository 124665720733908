import { Filter } from '@/interfaces/filter';
import { AnalyticType } from '@/interfaces/analytic-type';
import { EnvType } from '@/interfaces/env';

export enum ModelStatusType {
  Initial = 'Initial',
  InProgress = 'In progress',
  Failed = 'Failed',
  Canceled = 'Canceled',
  Finished = 'Finished'
}

export enum TrainingStatusType {
  Initial = 'Initial',
  InProgress = 'In progress',
  Failed = 'Failed',
  Canceled = 'Canceled',
  Publishing = 'Publishing',
  Finished = 'Finished'
}

export interface Environment {
  type: EnvType;
  progress?: number;
  status?: ModelStatusType;
}

export interface Model {
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  version?: string;
  analyticType: AnalyticType;
  trainingStatus?: TrainingStatusType;
  trainingProgress?: number;
  buildStatus?: string;
  buildProgress?: number;
  envs?: Environment[];
  trainingStartAt?: string; // '2021-03-19T11:22:44.118Z'
  trainingFinishAt?: string; // '2021-03-19T11:22:44.118Z'
  trainingSetId?: string;
  notes?: string;
  buildErrorLog?: string;
  imageName?: string;
}

export interface ModelState {
  data: Model[];
  filteredData: Model[];
  filter: Filter;
}

export interface ModelResponse {
  success: boolean;
  status?: string;
  message?: string;
  id?: string;
}
