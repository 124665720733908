import { AnalyticPrefix } from '@/enums/analytic-prefix-release';
import { Repository } from '@/enums/repos';

export default class Constants {
  static DS_REPOS = [
    Repository.HARVEST_PIXEL_BASED,
    Repository.SUGARCONTENT_YIELD_BRAZIL,
    Repository.NEMATODE,
    Repository.NDVI_FUSION_ANALYTIC,
    Repository.SOWING_SUGARCANE,
    Repository.DEEP_LEARNING,
    Repository.LAND_USE
  ];

  static DS_REPO_MULTIDOCKER = [Repository.NEMATODE, Repository.DEEP_LEARNING];

  static DS_REPO_ANALYTIC_MAP = {
    [Repository.NEMATODE]: AnalyticPrefix.NEMATODE,
    [Repository.DEEP_LEARNING]: AnalyticPrefix.WEEDS
  };

  static DS_REPO_LABELS = {
    [Repository.HARVEST_PIXEL_BASED]: 'harvest, deforestation',
    [Repository.SUGARCONTENT_YIELD_BRAZIL]: 'sugar',
    [Repository.NEMATODE]: 'nematodes',
    [Repository.NDVI_FUSION_ANALYTIC]: 'ndvi',
    [Repository.SOWING_SUGARCANE]: 'sowing',
    [Repository.DEEP_LEARNING]: 'weeds',
    [Repository.LAND_USE]: 'land-use'
  };
}
