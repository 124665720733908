import Vue from 'vue';
import Vuex from 'vuex';

import { RootState } from '@/interfaces/root-state';
import { UserInfo } from '@/interfaces/user-info';
import API from '@/services/rest-api';

import { repo } from './modules/repo';
import { model } from './modules/model';
import { run } from './modules/run';
import * as Sentry from '@sentry/vue';
import { AuthorizeResponse } from '@/interfaces/auth';

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  state: {
    showGlobalLoader: false,
    userInfo: null
  },
  mutations: {
    showGlobalLoader(state, payload) {
      state.showGlobalLoader = payload;
    },
    setUserInfo: (state, userInfo: UserInfo) => {
      state.userInfo = userInfo;
    },
    setUserOrgs: (state, orgs: string[]) => {
      state.orgs = orgs;
    }
  },
  actions: {
    showGlobalLoader({ commit }, isShow) {
      commit('showGlobalLoader', isShow);
    },
    async authorize({ commit, dispatch }) {
      dispatch('showGlobalLoader', true);
      try {
        const res = await API.authorize();
        commit('setUserInfo', res.UserInfo);
        Sentry.setUser({ email: res.UserInfo.Email, username: res.UserInfo.FirstName + ' ' + res.UserInfo.LastName });
      } catch (error) {
        window.location.href = `${process.env.VUE_APP_AUTH_SERVER}?redir=${encodeURIComponent(window.location.href)}`;
      } finally {
        dispatch('showGlobalLoader', false);
      }
    }
  },
  modules: {
    repo,
    run,
    model
  }
});
