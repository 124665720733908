import { ActionTree, Module, MutationTree } from 'vuex';

import { Filter } from '@/interfaces/filter';
import { Run, RunState } from '@/interfaces/run';
import { RootState } from '@/interfaces/root-state';
import API from '@/services/rest-api';

export const state: RunState = {
  data: [],
  filteredData: [],
  filter: {
    search: ''
  }
};

export const actions: ActionTree<RunState, RootState> = {
  async loadData({ commit, dispatch, state }) {
    const data = await API.getRuns();
    commit('setData', data);
    dispatch('filterData', state.filter);
  },

  filterData({ commit, dispatch }, filter: Filter) {
    dispatch('showGlobalLoader', true, { root: true });
    commit('filterData', filter);
    dispatch('showGlobalLoader', false, { root: true });
  }
};

export const mutations: MutationTree<RunState> = {
  setData: (state, data: Run[]) => {
    state.data = data;
  },

  filterData: (state, filter: Filter) => {
    if (!filter.search) {
      state.filteredData = state.data;
    }
    state.filter = filter;
    state.filteredData = state.data.filter((dataItem: Run) => {
      const { modelVersion = '' } = dataItem;
      return modelVersion.toLowerCase().includes(filter.search.toLowerCase());
    });
  }
};

export const run: Module<RunState, RootState> = { namespaced: true, state, actions, mutations };
