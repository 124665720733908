export function getUnitByMetric(param: string): string {
  const unitPerParam = {
    gapsLength: 'm',
    linesLength: 'm',
    curatedGapsLength: 'm',
    curatedLinesLength: 'm',
    gapsAccuracy: '%',
    gapsLengthAccuracy: '%',
    linesAccuracy: '%',
    linesLengthAccuracy: '%',
    iouPercent: '%',
    default: ''
  };
  return unitPerParam[param] || unitPerParam['default'];
}
