import { AnalyticType } from '@/interfaces/analytic-type';
import * as tilebelt from '@mapbox/tilebelt';
import { Feature, Polygon, feature } from '@turf/helpers';
import bbox from '@turf/bbox';
export class AnalyticRegion {
  surveyId: string;
  x: number;
  y: number;
  type: AnalyticType;
  z = 19; // we use predefined zoom 19 for regions
  zoomPerAnalyticType = {
    [AnalyticType.SOWING]: 19,
    [AnalyticType.WEEDS]: 20,
    default: 19
  };
  constructor(surveyId: string, x: number, y: number, analyticType?: AnalyticType) {
    this.surveyId = surveyId;
    this.x = x;
    this.y = y;
    const zoomKey = analyticType ? analyticType : 'default';
    this.z = this.zoomPerAnalyticType[zoomKey];
  }

  toGeoJSON(): Feature<Polygon> {
    return feature(tilebelt.tileToGeoJSON([this.x, this.y, this.z]));
  }

  getPolygonCoordinates(): number[][] {
    const bbx = bbox(tilebelt.tileToGeoJSON([this.x, this.y, this.z]));
    return [
      [bbx[0], bbx[3]],
      [bbx[2], bbx[3]],
      [bbx[2], bbx[1]],
      [bbx[0], bbx[1]]
    ];
  }
}

class AnalyticRegionService {
  static parseId(id: string, analyticType: AnalyticType): AnalyticRegion {
    const parts = id.split('_');
    if (parts.length !== 3) {
      throw new Error(`Can't parse tileId, tileId: ${id}`);
    }
    return new AnalyticRegion(parts[0], parseInt(parts[1]), parseInt(parts[2]), analyticType);
  }
}

export default AnalyticRegionService;
