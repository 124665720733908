export default function (value: number): string {
  if (value) {
    const seconds = value / 1000;
    const hours = seconds / 3600;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return `${rhours}h${rminutes}m`;
  }
}
