
import { Component, Vue } from 'vue-property-decorator';
import { EnvType } from '@/interfaces/env';
import { SubModel } from '@/interfaces/subModel';

import API from '@/services/rest-api';

export interface MenuEvent extends Event {
  key: string;
}

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: 'Dev',
    scopedSlots: { customRender: 'devDeploy' }
  },
  {
    title: 'Preprod',
    scopedSlots: { customRender: 'preprodDeploy' }
  },
  {
    title: 'Prod',
    scopedSlots: { customRender: 'prodDeploy' }
  }
];
export enum SubModelType {
  CLOUDFILTER = 'cloudFilter',
  GEOTIFF = 'geotiff'
}

@Component({
  components: {}
})
export default class SubModelTable extends Vue {
  EnvType = EnvType;

  private columns = columns;

  private subModelCurrentVersions = {
    [SubModelType.CLOUDFILTER]: {
      [EnvType.DEV]: '',
      [EnvType.PREPROD]: '',
      [EnvType.PROD]: ''
    },
    [SubModelType.GEOTIFF]: {
      [EnvType.DEV]: '',
      [EnvType.PREPROD]: '',
      [EnvType.PROD]: ''
    }
  };

  async loadCurrenSubModelsVersion(): Promise<void> {
    this.dataSource.forEach(async (subModel) => {
      [EnvType.DEV, EnvType.PREPROD, EnvType.PROD].forEach(async (env) => {
        try {
          const deployLog = await API.getLatestDeployLog(subModel.type as string, env);
          this.subModelCurrentVersions[subModel.type][env] = deployLog.version;
        } catch (error) {
          // skip 404 error
          this.subModelCurrentVersions[subModel.type][env] = '';
        }
      });
    });
  }

  async mounted(): Promise<void> {
    await this.loadCurrenSubModelsVersion();
  }

  get dataSource(): SubModel[] {
    return [
      {
        name: 'Cloud-filter (satellite-service)',
        type: SubModelType.CLOUDFILTER,
        devVersion: '',
        preprodVersion: '',
        prodVersion: ''
      },
      {
        name: 'GeoTiff (product-manager)',
        type: SubModelType.GEOTIFF,
        devVersion: '',
        preprodVersion: '',
        prodVersion: ''
      }
    ];
  }

  get tableScrollOpt(): { y: number } {
    const headerHeight = 180;
    return { y: window.innerHeight - headerHeight };
  }

  async handelDeploy(version: string, type: string, env: EnvType): Promise<void> {
    this.$store.dispatch('showGlobalLoader', true);

    try {
      await API.deploySubModel(version, type, env);
      await this.loadCurrenSubModelsVersion();
    } finally {
      this.$store.dispatch('showGlobalLoader', false);
    }
  }
}
