
import { Component, Vue, Watch } from 'vue-property-decorator';
import Map from '@/components/Map.vue';
import { Tile } from '@/interfaces/run';
import API from '@/services/rest-api';
import { getUnitByMetric } from '@/services/unit';
import { AnalyticType } from '@/interfaces/analytic-type';

@Component({
  components: { Map }
})
export default class RunTiles extends Vue {
  tiles: Tile[] = [];
  analyticType: AnalyticType = null;
  selectedTile: Tile = null;

  get runId(): string {
    return this.$route.params.id;
  }

  get selectedTileId(): string {
    return this.selectedTile?.id;
  }

  created(): void {
    // fetch the data when the view is created and the data is
    // already being observed
    this.loadTiles();
  }

  @Watch('$route')
  onRouteChange(): void {
    this.loadTiles();
  }

  async loadTiles(): Promise<void> {
    if (!this.runId) {
      return;
    }
    const run = await API.getRunById(this.runId);
    if (run) {
      this.tiles = run.testSet;
      this.analyticType = run.analyticType;
    }
  }

  onTileClick(id: string): void {
    const tile = this.tiles.find((tile) => tile.id === id);
    if (tile) {
      this.selectedTile = tile;
    }
  }

  getUnit(param: string): string {
    return getUnitByMetric(param);
  }
}
