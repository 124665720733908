
import { Component, Vue } from 'vue-property-decorator';
import GlobalLoader from '@/components/GlobalLoader.vue';
import API from '@/services/rest-api';

@Component({
  components: {
    GlobalLoader
  }
})
export default class App extends Vue {
  private refreshSessionInterval = 600000; // millisecond, 10 min

  mounted(): void {
    setInterval(() => {
      /* eslint-disable-next-line no-console */
      console.info('refreshSession...');
      API.refreshSession();
    }, this.refreshSessionInterval);
    this.openFeedback();
  }

  openFeedback(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const userSnap = (window as any).userSnap;
    if (userSnap) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      userSnap.on('open', (event: any) => {
        event.api.setValue('visitor', this.$store.state.userInfo ? this.$store.state.userInfo.Email : null);
        event.api.setValue('custom', this.$store.state.userInfo);
      });
    }
  }
}
