export enum Repository {
  ORB2 = 'orb2',
  VTSERVER = 'vtserver',
  AUTH_SERVICE = 'auth-service',
  PRODUCT_MANAGER = 'product-manager',
  ANALYTIC_LAUNCHER = 'analytic-launcher',
  SATELLITE_SERVICE = 'satellite-service',
  SAT_EXPLORER = 'sat-explorer',
  CURATION_SERVICE = 'curation-service',
  SHAPE_MANAGER = 'shape-manager',
  CLOUDS_CURATION = 'clouds-curation',
  DEPLOY_MANAGER = 'deploy-manager',
  DEPLOY_SERVICE = 'deploy-service',
  BENCHMARK_MODELS = 'benchmark-models',
  DRONE_SERVICE = 'drone-service',
  WEEDS_CURATION = 'weeds-curation',
  CURATION_OPERATOR_VIEW = 'curation-operator-view',
  CURATION_CURATOR_VIEW = 'curation-curator-view',
  LABELLING = 'labelling',
  LABELLING_SERVICE = 'labelling-service',
  DASHBOARD_VIEW = 'dashboard-view',
  DASHBOARD_SERVICE = 'dashboard-service',
  ALIGNMENT_CLIENT = 'alignment-client',
  ALIGNMENT_SERVICE = 'alignment-service',
  UPLOADER = 'uploader',
  HARVEST_PIXEL_BASED = 'harvest-pixel-based',
  NDVI_FUSION_ANALYTIC = 'ndvi-fusion-analytic',
  SUGARCONTENT_YIELD_BRAZIL = 'sugarcontent_yield_brazil',
  LAND_USE = 'land-classification',
  GAMAYAPY = 'gamayapy',
  NEMATODE = 'nematode',
  SOWING_SUGARCANE = 'dl_sugarcane',
  DEEP_LEARNING = 'DeepLearning'
}
