
import { Component, Vue } from 'vue-property-decorator';

import ColsSvg from '@/assets/icons/cols.svg';
import API from '@/services/rest-api';
import GithubApi from '@/services/github-api';

import { Repo } from '@/interfaces/repo';
import { EnvType } from '@/interfaces/env';
import { Repository } from '@/enums/repos';
import BenchmarkRun from './BenchmarkRun.vue';

const columns = [];

const innerColumns = [];

export interface Column {
  title: string;
  dataIndex?: string;
  key: string;
  scopedSlots?: { customRender?: string };
  sorter?: (a: unknown, b: unknown) => number;
  filters?: { text?: string; value?: string | number }[];
  onFilter?: (value: string, record: unknown) => boolean;
  order?: number;
}

export interface CheckBoxTarget extends EventTarget {
  checked: boolean;
}
export interface CheckBoxEvent extends Event {
  target: CheckBoxTarget;
}

@Component({
  components: {
    ColsSvg
  }
})
export default class ReposTable extends Vue {
  private innerColumns = innerColumns;
  private columns: Column[] = columns;

  groupOptions = [
    {
      label: 'Repo',
      value: 'repo',
      scopedSlots: { customRender: 'repo' },
      order: 11,
      defaultSortOrder: 'ascend',
      sorter: (a: Repo, b: Repo): number => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
    },
    {
      label: 'PRs',
      value: 'prs',
      scopedSlots: { customRender: 'prs' },
      order: 9,
      defaultSortOrder: 'descend',
      sorter: (a: Repo, b: Repo): number => a.prs.length - b.prs.length
    },
    { label: 'Dev', value: 'dev', scopedSlots: { customRender: 'dev' }, order: 8 },
    {
      label: 'Preprod',
      value: 'preprod',
      scopedSlots: { customRender: 'preprod' },
      order: 8,
      defaultSortOrder: 'descend',
      sorter: (a: Repo, b: Repo): number => a.prod?.diff?.commits?.length - b.prod?.diff?.commits?.length
    },
    {
      label: 'Prod',
      value: 'prod',
      scopedSlots: { customRender: 'prod' },
      order: 8,
      defaultSortOrder: 'descend',
      sorter: (a: Repo, b: Repo): number => a.prod?.diff?.commits?.length - b.prod?.diff?.commits?.length
    }
  ];
  defaultCheckedList = ['repo', 'prs', 'dev', 'preprod', 'prod'];
  indeterminate = true;
  checkAll = false;
  checkedList = this.defaultCheckedList;

  env = '';
  isEnvVisible = false;

  get repos(): Repo[] {
    return this.$store.state.repo.filteredRepos;
  }

  onSearch(value: string): void {
    this.$store.dispatch('repo/filterRepos', { search: value });
  }

  async mounted(): Promise<void> {
    this.$store.dispatch('repo/loadRepos');
    this.updateColumns();
  }

  public async openProdRelease(repo: Repo): Promise<void> {
    if (this.shouldValidateEnv(repo)) {
      const isValidRelease = await this.validateEnv(EnvType.PROD, repo);

      if (!isValidRelease) return;
    }

    const url = `https://github.com/GamayaSpectral/${repo.name}/releases/new?tag=${repo.preprod.name}&title=${repo.preprod.name}`;
    window.open(url, '_blank');
  }

  async createPreprodRelease(repo: Repo): Promise<void> {
    if (this.shouldValidateEnv(repo)) {
      const isValidRelease = await this.validateEnv(EnvType.PREPROD, repo);

      if (!isValidRelease) return;
    }

    if (!confirm('Are you sure?')) return;

    await GithubApi.createTag(repo);
  }

  private async validateEnv(env: EnvType, repo: Repo): Promise<boolean> {
    let resp = null;
    let missing = [];
    let extra = [];

    try {
      await this.$store.dispatch('showGlobalLoader', true);
      resp = await API.validateEnv(repo.name, env);
      missing = Object.keys(resp.missing || {});
      extra = Object.keys(resp.extra || {});
    } catch (error) {
      return false;
    } finally {
      await this.$store.dispatch('showGlobalLoader', false);
    }

    let msg = '';

    if (missing.length > 0) {
      msg = 'There are missing variables in ' + env + ' env file: \n\n' + missing.join('\n');
    }

    if (extra.length > 0) {
      msg +=
        '\n\nThere were extra variables in ' +
        env +
        ' env file. Those were removed from the .env file: \n\n' +
        extra.join('\n');
    }

    if (msg.length) {
      alert(msg);
    }

    return missing.length === 0;
  }

  updateColumns(): void {
    const extraCols = this.checkedList.map((checkedItem) => {
      const option = this.groupOptions.find((groupOption) => {
        return groupOption.value === checkedItem;
      });

      return {
        title: option.label,
        dataIndex: option.value,
        key: option.value,
        ...option
      } as Column;
    });

    const sortedExtraCols = extraCols.sort((a: Column, b: Column): number => b.order - a.order);
    this.columns = [
      ...sortedExtraCols.slice(0, 5),
      ...columns.slice(0, 1),
      ...sortedExtraCols.slice(5, sortedExtraCols.length),
      ...columns.slice(1, columns.length)
    ];
    // slice use to keep sorting of columns
  }

  onColsChange(checkedList: string[]): void {
    this.indeterminate = !!checkedList.length && checkedList.length < this.groupOptions.length;
    this.checkAll = checkedList.length === this.groupOptions.length;
    this.updateColumns();
  }

  onColsCheckAllChange(e: CheckBoxEvent): void {
    this.checkedList = e.target.checked ? this.groupOptions.map((opt) => opt.value) : [];
    this.indeterminate = false;
    this.checkAll = e.target.checked;
    this.updateColumns();
  }

  get tableScrollOpt(): { y: number; x?: number } {
    return { y: window.innerHeight - 230 };
  }

  async displayEnvFile(name: string, env: string): Promise<void> {
    let envContent = '';
    try {
      await this.$store.dispatch('showGlobalLoader', true);
      envContent = await API.getEnv(name, env);
      this.isEnvVisible = true;
    } catch {
      this.isEnvVisible = false;
    } finally {
      this.env = envContent;
      await this.$store.dispatch('showGlobalLoader', false);
    }
  }

  private isBackend(repo: Repo): boolean {
    return repo.type === 'backend' && repo?.lang?.toLowerCase() === 'go';
  }

  private shouldValidateEnv(repo: Repo): boolean {
    return Repository.DEPLOY_SERVICE !== repo.name && Repository.BENCHMARK_MODELS !== repo.name && this.isBackend(repo);
  }
}
