import { AnalyticType } from '@/interfaces/analytic-type';
import { ActionTree, Module, MutationTree, GetterTree } from 'vuex';

import { Filter } from '@/interfaces/filter';
import { Model, ModelState, TrainingStatusType } from '@/interfaces/model';
import { RootState } from '@/interfaces/root-state';
import API from '@/services/rest-api';

export const state: ModelState = {
  data: [],
  filteredData: [],
  filter: {
    search: ''
  }
};

export const actions: ActionTree<ModelState, RootState> = {
  async loadData({ commit, dispatch, state }) {
    const data = await API.getModels();
    commit('setData', data);
    dispatch('filterData', state.filter);
  },

  filterData({ commit, dispatch }, filter: Filter) {
    dispatch('showGlobalLoader', true, { root: true });
    commit('filterData', filter);
    dispatch('showGlobalLoader', false, { root: true });
  }
};

export const mutations: MutationTree<ModelState> = {
  setData: (state, data: Model[]) => {
    state.data = data;
  },

  filterData: (state, filter: Filter) => {
    if (!filter.search) {
      state.filteredData = state.data;
    }
    state.filter = filter;
    state.filteredData = state.data.filter((dataItem: Model) => {
      const { version = '' } = dataItem;
      return version.toLowerCase().includes(filter.search.toLowerCase());
    });
  }
};

export const getters: GetterTree<ModelState, RootState> = {
  versionsList: (state) => (analyticType: AnalyticType): string[] => {
    const readyModels = state.data.filter(
      (model) => model.trainingStatus === TrainingStatusType.Finished && model.analyticType === analyticType
    );
    return readyModels.map((model) => model.version);
  }
};

export const model: Module<ModelState, RootState> = { namespaced: true, state, actions, mutations, getters };
