import { render, staticRenderFns } from "./ReposTable.vue?vue&type=template&id=4bf1ef42&scoped=true&"
import script from "./ReposTable.vue?vue&type=script&lang=ts&"
export * from "./ReposTable.vue?vue&type=script&lang=ts&"
import style0 from "./ReposTable.vue?vue&type=style&index=0&id=4bf1ef42&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bf1ef42",
  null
  
)

export default component.exports