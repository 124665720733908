
import { Component, Vue } from 'vue-property-decorator';
import EditableCell from '@/components/EditableCell.vue';
import API from '@/services/rest-api';
import { Country } from '@/interfaces/country';
import { Organization } from '../interfaces/organization';
import { Unit } from '@/interfaces/unit';
import { message } from 'ant-design-vue';
import { TableFilter } from '@/interfaces/table';

const columns = [
  {
    title: 'Org',
    dataIndex: 'OrganizationName',
    key: 'OrganizationName',
    onFilter: (value, record) => record.OrganizationID === value
  },
  {
    title: 'Mill',
    dataIndex: 'Name',
    key: 'Name',
    width: '270px',
    scopedSlots: { customRender: 'Name' },
    filters: [],
    onFilter: (value, record) => record.id === value
  },
  {
    title: 'Sugar Model',
    dataIndex: 'SugarModel',
    key: 'SugarModel',
    scopedSlots: { customRender: 'SugarModel' },
    onFilter: (value, record) => record.SugarModel === value
  },
  {
    title: 'Agronomic Model',
    dataIndex: 'AgronomicModel',
    key: 'AgronomicModel',
    scopedSlots: { customRender: 'AgronomicModel' },
    onFilter: (value, record) => record.AgronomicModel === value
  },
  {
    title: 'Ripener Model',
    dataIndex: 'RipenerModel',
    key: 'RipenerModel',
    scopedSlots: { customRender: 'RipenerModel' },
    onFilter: (value, record) => record.RipenerModel === value
  },
  {
    title: 'Model Type',
    dataIndex: 'ModelType',
    key: 'ModelType',
    scopedSlots: { customRender: 'ModelType' }
  },

  {
    title: 'Last Run',
    dataIndex: 'LastRun',
    key: 'LastRun',
    scopedSlots: { customRender: 'LastRun' }
  },
  {
    title: 'Last Release Date',
    dataIndex: 'ModelLastReleaseDate',
    key: 'ModelLastReleaseDate',
    scopedSlots: { customRender: 'ModelLastReleaseDate' }
  },
  {
    title: 'Last Manual Run Date',
    dataIndex: 'ModelLastManualRunDate',
    key: 'ModelLastManualRunDate',
    scopedSlots: { customRender: 'ModelLastManualRunDate' }
  },
  {
    title: 'Notes',
    dataIndex: 'ModelNotes',
    key: 'ModelNotes',
    scopedSlots: { customRender: 'ModelNotes' }
  }
];

@Component({
  components: {
    EditableCell
  }
})
export default class MappingTable extends Vue {
  public columns = columns;
  public dataSource: Unit[] = [];
  tableFilter: TableFilter = {}; // filters chosen on table, uses only for export data what user currently see

  async mounted() {
    const data = await API.getMenu();
    const dataSource = [];

    const unitNames = new Set();
    const orgNames = new Set();
    const sugarModelNames = new Set();
    const ripenerModelNames = new Set();
    const agronomicModelNames = new Set();

    const units = [];
    const orgs = [];
    const sugarModels = [];
    const ripenerModels = [];
    const agronomicModels = [];

    data.forEach((country: Country) => {
      country.Organizations.forEach((org: Organization) => {
        if (!orgNames.has(org.Name)) {
          orgs.push({ text: org.Name, value: org.id });
          orgNames.add(org.Name);
        }

        org.Units.forEach((unit: Unit) => {
          if (unit?.Seasons?.length) {
            unit.OrganizationName = org.Name;
            unit.OrganizationID = org.id;
            dataSource.push(unit);

            if (unit.SugarModel && !sugarModelNames.has(unit.SugarModel)) {
              sugarModels.push({ text: unit.SugarModel, value: unit.SugarModel });
              sugarModelNames.add(unit.SugarModel);
            }

            if (unit.RipenerModel && !ripenerModelNames.has(unit.RipenerModel)) {
              ripenerModels.push({ text: unit.RipenerModel, value: unit.RipenerModel });
              ripenerModelNames.add(unit.RipenerModel);
            }

            if (unit.AgronomicModel && !agronomicModelNames.has(unit.AgronomicModel)) {
              agronomicModels.push({ text: unit.AgronomicModel, value: unit.AgronomicModel });
              agronomicModelNames.add(unit.AgronomicModel);
            }

            if (unit.Name && !unitNames.has(unit.Name)) {
              units.push({ text: unit.Name, value: unit.id });
              unitNames.add(unit.Name);
            }
          }
        });
      });
    });

    this.columns.forEach((column) => {
      if (column.dataIndex === 'Name') {
        column.filters = units;
      }
      if (column.dataIndex === 'OrganizationName') {
        column.filters = orgs;
      }
      if (column.dataIndex === 'SugarModel') {
        column.filters = sugarModels;
      }
      if (column.dataIndex === 'RipenerModel') {
        column.filters = ripenerModels;
      }
      if (column.dataIndex === 'AgronomicModel') {
        column.filters = agronomicModels;
      }
    });

    this.dataSource = dataSource;
  }

  get tableScrollOpt(): { y: number } {
    const headerHeight = 180;
    return { y: window.innerHeight - headerHeight };
  }

  async handleNotesChange(unit: Unit, key: string, value: string): Promise<void> {
    const unitToUpdate: Partial<Unit> = {
      id: unit.id,
      [key]: value
    };

    try {
      await API.updateUnit(unitToUpdate);
    } catch {
      message.error('Failed to update unit', 5);
    }
  }

  onTableChange(_, filters: TableFilter): void {
    this.tableFilter = filters;
  }
}
