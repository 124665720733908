import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route } from 'vue-router';

import ReposTable from '@/components/ReposTable.vue';
import ModelBuild from '@/components/ModelBuild.vue';
import BenchmarkRun from '@/components/BenchmarkRun.vue';
import SubModelTable from '@/components/SubModelTable.vue';
import Mapping from '@/components/Mapping.vue';

import { PageName } from '@/enums/page-name';
import { Role } from '@/enums/role';
import store from '@/store';
import Dashboard from '@/views/Dashboard.vue';
import RunTiles from '@/views/RunTiles.vue';

Vue.use(VueRouter);

export function onlyAdmin(to: Route, from: Route, next: NavigationGuardNext): void {
  if (store.state.userInfo.Role !== Role.admin) {
    next({ name: PageName.DEPLOY_DS });
  } else {
    next();
  }
}

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext) => {
      await store.dispatch('authorize');
      next();
    },
    children: [
      {
        path: '/deploy_sw',
        name: PageName.DEPLOY_SW,
        component: ReposTable
        // beforeEnter: onlyAdmin
      },
      {
        path: '/run_tiles/:id',
        name: PageName.RUN_TILES,
        component: RunTiles
      },
      {
        path: '/model_build',
        name: PageName.MODEL_BUILD,
        component: ModelBuild
      },
      {
        path: '/benchmark_run',
        name: PageName.BENCHMARK_RUN,
        component: BenchmarkRun
      },

      {
        path: '/sub_model',
        name: PageName.SUB_MODEL,
        component: SubModelTable
      },

      {
        path: '/mapping',
        name: PageName.MAPPING,
        component: Mapping
      },

      { path: '*', redirect: '/deploy_sw' }
    ]
  },
  { path: '*', redirect: '/' }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
