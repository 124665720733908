
import Mapbox from 'mapbox-gl-vue';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Map as MapboxMap, LngLat, ImageSourceOptions, GeoJSONSource, ImageSource } from 'mapbox-gl';
import { FeatureCollection, featureCollection, GeoJSONObject } from '@turf/helpers';
import { message } from 'ant-design-vue';
import { Tile } from '@/interfaces/run';
import center from '@turf/center';
import API from '@/services/rest-api';
import AnalyticRegionService from '@/services/analytic-region';
import { AnalyticRegion } from '@/services/analytic-region';
import { AnalyticType } from '@/interfaces/analytic-type';

@Component({
  components: {
    Mapbox
  }
})
export default class Map extends Vue {
  @Prop() readonly selectedTile: Tile;
  @Prop() readonly selectedRunId: string;
  @Prop() readonly analyticType: AnalyticType;

  AnalyticTypeEnum = AnalyticType;
  private map: MapboxMap;

  private readonly segmentColorMap = {
    gap: '#fc0303',
    line: '#03fc7b',
    default: '#000'
  };
  private readonly weedsFillColor = '#ffff00';
  private readonly analyticRegionShapePaint = { 'line-width': 1, 'line-color': '#fff' };

  private readonly modelAnalyticSourceId = 'modelAnalyticSourceId';
  private readonly modelAnalyticLayerId = 'modelAnalyticLayerId';

  private readonly modelRawSourceId = 'modelRawSourceId';
  private readonly modelRawLayerId = 'modelRawLayerId';

  private readonly modelClippedSourceId = 'modelClippedSourceId';
  private readonly modelClippedLayerId = 'modelClippedLayerId';

  private readonly curatedAnalyticSourceId = 'curatedAnalyticSourceId';
  private readonly curateAnalyticLayerId = 'curatedAnalyticLayerId';

  private readonly analyticRegionShapeSourceId = 'analyticRegionShapeSource';
  private readonly analyticRegionShapeLayerId = 'analyticRegionShapeLayer';

  private readonly rasterSourceId = 'rasterSource';
  private readonly rasterLayerId = 'rasterLayer';

  private readonly analyticRasterLayerId = 'analyticRasterLayer';
  private readonly analyticRasterSourceId = 'analyticRasterSource';
  private readonly labelledRasterLayerId = 'labelledRasterLayer';
  private readonly labelledRasterSourceId = 'labelledRasterSource';

  private modelAnalytic: FeatureCollection = null;
  private curatedAnalytic: FeatureCollection = null;
  private modelRaw: FeatureCollection = null;
  private modelClipped: FeatureCollection = null;

  isShowModelAnalytic = true;
  isShowCuratedAnalytic = false;
  isShowModelClipped = false;
  isShowModelRaw = false;
  isShowAnalyticRaster = false;
  isShowLabelledRaster = false;

  @Watch('selectedTile')
  onTileChange(): void {
    this.reset();
    this.onLoad();
  }

  reset(): void {
    this.modelAnalytic = null;
    this.curatedAnalytic = null;
    this.isShowModelAnalytic = true;
    this.isShowCuratedAnalytic = false;
    this.isShowModelRaw = false;
    this.isShowModelClipped = false;
    this.isShowAnalyticRaster = false;
    this.isShowLabelledRaster = false;

    this.updateGeoJsonSource(this.curatedAnalyticSourceId, featureCollection([]));
    this.updateGeoJsonSource(this.modelRawSourceId, featureCollection([]));
    if (this.analyticType === AnalyticType.SOWING) {
      this.updateGeoJsonSource(this.modelClippedSourceId, featureCollection([]));
    }
  }

  private async onLoad(): Promise<void> {
    const tileId = this.selectedTile?.id;
    if (!tileId || !this.selectedRunId) {
      this.$store.dispatch('showGlobalLoader', false);
      return;
    }
    try {
      this.$store.dispatch('showGlobalLoader', true);

      const resultType = this.analyticType === AnalyticType.SOWING ? 'processed.geojson' : 'final.json';
      this.modelAnalytic = await API.getAnalytic(this.selectedRunId, tileId, resultType);

      const analyticRegion = AnalyticRegionService.parseId(tileId, this.analyticType);
      const analyticRegionShape = analyticRegion.toGeoJSON();
      if (analyticRegionShape) {
        const coordinates = center(analyticRegionShape).geometry.coordinates;
        this.map.setCenter(new LngLat(coordinates[0], coordinates[1]));
      }

      this.drawRasterTiles(analyticRegion.surveyId);
      this.drawModelAnalytic();
      this.drawRegionShape(analyticRegion);
      this.drawAnalyticRasterLayer();
      if (this.analyticType === AnalyticType.WEEDS) {
        this.drawLabelledRasterLayer();
      }
      this.reOrderLayers();
    } catch (err) {
      message.error('Something went wrong: ' + err, 5);
    } finally {
      this.$store.dispatch('showGlobalLoader', false);
    }
  }

  private drawModelAnalytic(): void {
    if (this.modelAnalytic) {
      this.updateGeoJsonSource(this.modelAnalyticSourceId, this.modelAnalytic);
      this.showModelAnalyticLayer();
    }
  }

  private async drawModelRaw(): Promise<void> {
    if (!this.modelRaw) {
      this.modelRaw = await API.getAnalytic(
        this.selectedRunId,
        this.selectedTile.id,
        this.analyticType === AnalyticType.WEEDS ? 'output.json' : 'output.geojson'
      );
    }
    if (this.modelRaw) {
      this.updateGeoJsonSource(this.modelRawSourceId, this.modelRaw);
    } else {
      message.error('Raw analytic output not available for ' + this.selectedTile.id);
    }
  }

  private async drawModelClipped(): Promise<void> {
    if (!this.modelClipped) {
      this.modelClipped = await API.getAnalytic(
        this.selectedRunId,
        this.selectedTile.id,
        this.analyticType === AnalyticType.WEEDS ? 'final.json' : 'final.geojson'
      );
    }
    if (this.modelClipped) {
      this.updateGeoJsonSource(this.modelClippedSourceId, this.modelClipped);
    } else {
      message.error('Clipped analytic output not available for ' + this.selectedTile.id);
    }
  }

  mounted(): void {
    this.onLoad();
  }

  private createCuratedAnalyticLayer(): void {
    this.createLayer(this.curatedAnalyticSourceId, this.curateAnalyticLayerId);
  }

  private createRasterLayer(sourceId: string, layerId: string, sourceParams: ImageSourceOptions) {
    this.cleanupLayer(layerId, sourceId);

    this.map.addSource(sourceId, {
      type: 'image',
      url: sourceParams.url,
      coordinates: sourceParams.coordinates
    });

    this.map.addLayer({
      id: layerId,
      type: 'raster',
      source: sourceId
    });
  }

  private createLayer(sourceId: string, layerId: string): void {
    this.map.addSource(sourceId, {
      type: 'geojson',
      data: featureCollection([])
    });

    switch (this.analyticType) {
      case AnalyticType.SOWING:
        this.map.addLayer({
          type: 'line',
          id: layerId,
          source: sourceId,
          paint: {
            ['line-width']: 1,
            ['line-opacity']: 0.8,
            ['line-color']: [
              'case',
              ['==', ['get', 'type'], 'line'],
              this.getSegmentColor('line'),
              ['==', ['get', 'type'], 'gap'],
              this.getSegmentColor('gap'),
              this.segmentColorMap.default
            ]
          }
        });
        break;
      case AnalyticType.WEEDS:
        this.map.addLayer({
          type: 'line',
          id: layerId,
          source: sourceId,
          paint: {
            ['line-color']: this.weedsFillColor
          }
        });
        break;
      default:
        this.map.addLayer({
          type: 'fill',
          id: layerId,
          source: sourceId
        });
        break;
    }
  }

  private createModelAnalyticLayer() {
    this.createLayer(this.modelAnalyticSourceId, this.modelAnalyticLayerId);
  }

  private createModelRawLayer() {
    this.createLayer(this.modelRawSourceId, this.modelRawLayerId);
  }

  private createModelClippedLayer() {
    this.createLayer(this.modelClippedSourceId, this.modelClippedLayerId);
  }

  private createLabelledRasterLayer() {
    const sourceParams = {
      url: `${API.STORAGE_BASE_URL}/${API.BENCHMARK_BUCKET}/${this.selectedRunId}/input/${this.selectedTile.id}_labelled.png`,
      coordinates: this.getTileBounds()
    };
    this.createRasterLayer(this.labelledRasterSourceId, this.labelledRasterLayerId, sourceParams);
  }

  drawLabelledRasterLayer(): void {
    if (this.map.getSource(this.labelledRasterSourceId) && this.map.getLayer(this.labelledRasterLayerId)) {
      this.updateImageSource(this.labelledRasterSourceId, {
        url: `${API.STORAGE_BASE_URL}/${API.BENCHMARK_BUCKET}/${this.selectedRunId}/input/${this.selectedTile.id}_labelled.png`,
        coordinates: this.getTileBounds()
      });
    } else {
      this.createLabelledRasterLayer();
    }
    this.hideLabelledRaster();
  }
  private adjustBounds(bounds) {
    let factor = this.analyticType === AnalyticType.SOWING ? 8 : 4;
    const xDelta = (bounds[2][0] - bounds[0][0]) / factor;
    const yDelta = (bounds[2][1] - bounds[0][1]) / factor;

    bounds[0][0] -= xDelta;
    bounds[0][1] -= yDelta;

    bounds[1][0] += xDelta;
    bounds[1][1] -= yDelta;

    bounds[2][0] += xDelta;
    bounds[2][1] += yDelta;

    bounds[3][0] -= xDelta;
    bounds[3][1] += yDelta;

    return bounds;
  }
  private createAnalyticRasterLayer() {
    let bounds = this.adjustBounds(this.getTileBounds());
    const folder = this.analyticType === AnalyticType.SOWING ? 'output' : 'input';
    const sourceParams = {
      url: `${API.STORAGE_BASE_URL}/${API.BENCHMARK_BUCKET}/${this.selectedRunId}/${folder}/${this.selectedTile.id}_output_classifier.png`,
      coordinates: bounds
    };
    this.createRasterLayer(this.analyticRasterSourceId, this.analyticRasterLayerId, sourceParams);
  }

  drawAnalyticRasterLayer(): void {
    if (this.map.getSource(this.analyticRasterSourceId) && this.map.getLayer(this.analyticRasterLayerId)) {
      let bounds = this.adjustBounds(this.getTileBounds());
      const folder = this.analyticType === AnalyticType.SOWING ? 'output' : 'input';
      this.updateImageSource(this.analyticRasterSourceId, {
        url: `${API.STORAGE_BASE_URL}/${API.BENCHMARK_BUCKET}/${this.selectedRunId}/${folder}/${this.selectedTile.id}_output_classifier.png`,
        coordinates: bounds
      });
    } else {
      this.createAnalyticRasterLayer();
    }
    this.hideAnalyticRaster();
  }

  private getTileBounds() {
    const analyticRegion = AnalyticRegionService.parseId(this.selectedTile.id, this.analyticType);
    return analyticRegion.getPolygonCoordinates();
  }

  private createTileShapeLayer(): void {
    this.map.addSource(this.analyticRegionShapeSourceId, {
      type: 'geojson',
      data: featureCollection([])
    });
    this.map.addLayer({
      type: 'line',
      id: this.analyticRegionShapeLayerId,
      source: this.analyticRegionShapeSourceId,
      paint: this.analyticRegionShapePaint
    });
  }

  private drawRegionShape(region: AnalyticRegion): void {
    this.updateGeoJsonSource(this.analyticRegionShapeSourceId, region.toGeoJSON());
  }

  onMapLoaded(map: MapboxMap): void {
    this.map = map;
    this.createModelAnalyticLayer();
    this.createModelRawLayer();
    if (this.analyticType === AnalyticType.SOWING) {
      this.createModelClippedLayer();
    }
    this.createCuratedAnalyticLayer();
    this.createTileShapeLayer();
  }

  private reOrderLayers(): void {
    const rasterLayerId = this.map.getLayer(this.rasterLayerId);
    const analyticRegionShapeLayerId = this.map.getLayer(this.analyticRegionShapeLayerId);
    const analyticRasterLayerId = this.map.getLayer(this.analyticRasterLayerId);
    const labelledRasterLayerId = this.map.getLayer(this.labelledRasterLayerId);
    const curateAnalyticLayerId = this.map.getLayer(this.curateAnalyticLayerId);
    const modelAnalyticLayerId = this.map.getLayer(this.modelAnalyticLayerId);
    const modelRawLayerId = this.map.getLayer(this.modelRawLayerId);
    const modelClippedLayerId = this.map.getLayer(this.modelClippedLayerId);
    // layers ordering from top to bottom
    const layersOrderingList = [
      modelClippedLayerId,
      modelRawLayerId,
      modelAnalyticLayerId,
      curateAnalyticLayerId,
      labelledRasterLayerId,
      analyticRasterLayerId,
      analyticRegionShapeLayerId,
      rasterLayerId
    ];

    const availableLayers = layersOrderingList.filter((layer) => !!layer);
    if (availableLayers.length > 1) {
      for (let i = availableLayers.length - 1; i > -1; i--) {
        this.map.moveLayer(availableLayers[i].id);
      }
    }
  }
  private updateGeoJsonSource(sourceId: string, geoJsonData: GeoJSONObject): void {
    const source = this.map.getSource(sourceId) as GeoJSONSource; // set data available only for geoJSON;
    if (source) {
      source.setData(geoJsonData as any);
    }
  }

  private updateImageSource(sourceId: string, params: ImageSourceOptions): void {
    const source = this.map.getSource(sourceId) as ImageSource;
    if (!source) {
      return;
    }

    source.updateImage(params);
  }

  private cleanupLayer(layerId: string, sourceId: string): void {
    if (this.map.getLayer(layerId)) {
      this.map.removeLayer(layerId);
    }
    if (this.map.getSource(sourceId)) {
      this.map.removeSource(sourceId);
    }
  }

  private getSegmentColor(type: string): string {
    return this.segmentColorMap[type] || this.segmentColorMap.default;
  }

  private drawRasterTiles(surveyId: string): void {
    this.cleanupLayer(this.rasterLayerId, this.rasterSourceId);
    this.map.addSource(this.rasterSourceId, {
      type: 'raster',
      tiles: [
        `https://storage.googleapis.com/${
          process.env.VUE_APP_DRONE_TILES_FOLDER
        }/${surveyId}/{z}/{x}/{y}.png?v=${new Date().getTime()}`
      ]
    });
    this.map.addLayer(
      {
        type: 'raster',
        id: this.rasterLayerId,
        source: this.rasterSourceId
      },
      this.modelAnalyticLayerId
    );
  }

  showAnalyticRaster(): void {
    this.map.setLayoutProperty(this.analyticRasterLayerId, 'visibility', 'visible');
  }

  showLabelledRaster(): void {
    this.map.setLayoutProperty(this.labelledRasterLayerId, 'visibility', 'visible');
  }

  hideAnalyticRaster(): void {
    this.map.setLayoutProperty(this.analyticRasterLayerId, 'visibility', 'none');
  }

  hideLabelledRaster(): void {
    this.map.setLayoutProperty(this.labelledRasterLayerId, 'visibility', 'none');
  }

  async drawCuratedAnalytic(): Promise<void> {
    if (!this.curatedAnalytic) {
      const curatedAnalytic = await API.getAnalytic(
        this.selectedRunId,
        this.selectedTile.id,
        this.analyticType === AnalyticType.WEEDS ? 'curated.json' : 'curated.geojson'
      );
      this.curatedAnalytic = curatedAnalytic as FeatureCollection;
    }
    if (this.curatedAnalytic) {
      this.updateGeoJsonSource(this.curatedAnalyticSourceId, this.curatedAnalytic);
      this.map.setLayoutProperty(this.curateAnalyticLayerId, 'visibility', 'visible');
    }
  }

  async hideCuratedAnalytic(): Promise<void> {
    this.map.setLayoutProperty(this.curateAnalyticLayerId, 'visibility', 'none');
  }

  async hideModelAnalyticLayer(): Promise<void> {
    this.map.setLayoutProperty(this.modelAnalyticLayerId, 'visibility', 'none');
  }

  async hideModelRawLayer(): Promise<void> {
    this.map.setLayoutProperty(this.modelRawLayerId, 'visibility', 'none');
  }

  async hideModelClippedLayer(): Promise<void> {
    this.map.setLayoutProperty(this.modelClippedLayerId, 'visibility', 'none');
  }

  async showModelAnalyticLayer(): Promise<void> {
    this.map.setLayoutProperty(this.modelAnalyticLayerId, 'visibility', 'visible');
  }

  async showModelRawLayer(): Promise<void> {
    this.drawModelRaw();
    this.map.setLayoutProperty(this.modelRawLayerId, 'visibility', 'visible');
  }

  async showModelClippedLayer(): Promise<void> {
    this.drawModelClipped();
    this.map.setLayoutProperty(this.modelClippedLayerId, 'visibility', 'visible');
  }

  toggleModelAnalytic(): void {
    if (this.isShowModelAnalytic) {
      this.showModelAnalyticLayer();
    } else {
      this.hideModelAnalyticLayer();
    }
  }

  toggleModelRaw(): void {
    if (this.isShowModelRaw) {
      this.showModelRawLayer();
    } else {
      this.hideModelRawLayer();
    }
  }

  toggleModelClipped(): void {
    if (this.isShowModelClipped) {
      this.showModelClippedLayer();
    } else {
      this.hideModelClippedLayer();
    }
  }

  toggleCuratedAnalytic(): void {
    if (this.isShowCuratedAnalytic) {
      this.drawCuratedAnalytic();
    } else {
      this.hideCuratedAnalytic();
    }
  }

  toggleLabelledRaster(): void {
    if (this.isShowLabelledRaster) {
      this.showLabelledRaster();
    } else {
      this.hideLabelledRaster();
    }
  }

  toggleAnalyticRaster(): void {
    if (this.isShowAnalyticRaster) {
      this.showAnalyticRaster();
    } else {
      this.hideAnalyticRaster();
    }
  }
}
