import { message } from 'ant-design-vue';
import Axios, { AxiosError } from 'axios';

import { AnalyticType } from '@/interfaces/analytic-type';
import { AuthorizeResponse, RefreshSessionResponse } from '@/interfaces/auth';
import { EnvType, ValidationResponse } from '@/interfaces/env';
import { Model, ModelResponse } from '@/interfaces/model';
import { Run, RunResponse } from '@/interfaces/run';
import { SubModelResponse } from '@/interfaces/subModel';
import { TestSet, TestSetResponse } from '@/interfaces/test-set';
import { TrainingSet, TrainingSetResponse } from '@/interfaces/training-set';
import { tileToGeoJSON } from '@mapbox/tilebelt';
import { feature, FeatureCollection, featureCollection } from '@turf/helpers';
import { DeployLog } from '@/interfaces/deploy-log';
import { Country } from '@/interfaces/country';
import { Unit } from '@/interfaces/unit';

class API {
  static _PRODUCT_MANAGER_URL = `${process.env.VUE_APP_PRODUCT_MANAGER}/api`;
  static _DEPLOY_SERVICE = `${process.env.VUE_APP_DEPLOY_SERVICE}/api`;
  public static STORAGE_BASE_URL = 'https://storage.googleapis.com';
  public static BENCHMARK_BUCKET = process.env.VUE_APP_BENCHMARK_BUCKET;

  static authorize(): Promise<AuthorizeResponse> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/authorize`, { withCredentials: true })
      .then((result) => result.data)
      .catch(() => false);
  }

  static getEnv(name: string, env: string): Promise<string> {
    return Axios.get(`${API._DEPLOY_SERVICE}/env/${name}/${env}`, { withCredentials: true })
      .then((result) => result.data)
      .catch((err) => API.handleError(err));
  }

  static validateEnv(name: string, env: string): Promise<ValidationResponse> {
    return Axios.get(`${API._DEPLOY_SERVICE}/env-validate/${name}/${env}`, { withCredentials: true })
      .then((result) => result.data)
      .catch((err) => API.handleError(err));
  }

  static async getRuns(): Promise<Run[]> {
    return Axios.get(`${API._DEPLOY_SERVICE}/runs`, { withCredentials: true })
      .then((result) => result.data)
      .catch((err) => API.handleError(err));
  }

  static async createRun(run: Run): Promise<RunResponse> {
    return Axios.post(`${API._DEPLOY_SERVICE}/runs`, run, { withCredentials: true })
      .then((result) => result.data)
      .catch((err) => API.handleError(err));
  }

  static getRunById(id: string): Promise<Run> {
    return Axios.get(`${API._DEPLOY_SERVICE}/runs/${id}`, { withCredentials: true })
      .then((result) => result.data)
      .catch((err) => API.handleError(err));
  }

  static benchmarkRun(id: string): Promise<RunResponse> {
    return Axios.post(`${API._DEPLOY_SERVICE}/runs/${id}/benchmark`, {}, { withCredentials: true })
      .then((result) => result.data)
      .catch((err) => API.handleError(err));
  }

  static updateRunById(id: string, data: Partial<Run>): Promise<{ success: boolean }> {
    return Axios.patch(`${API._DEPLOY_SERVICE}/runs/${id}`, data, { withCredentials: true })
      .then((result) => result.data)
      .catch((err) => API.handleError(err));
  }

  // load latest test tile and create new list if it is unique
  static syncTestSets(analyticType: AnalyticType): Promise<TestSetResponse> {
    return Axios.post(
      `${API._DEPLOY_SERVICE}/test-sets/sync`,
      { analyticType: analyticType },
      { withCredentials: true }
    )
      .then((result) => result.data)
      .catch((err) => API.handleError(err));
  }

  static async getTestSets(analyticType?: AnalyticType): Promise<TestSet[]> {
    return Axios.get(`${API._DEPLOY_SERVICE}/test-sets`, { withCredentials: true, params: { analyticType } })
      .then((result) => result.data)
      .catch((err) => API.handleError(err));
  }

  static async getModels(): Promise<Model[]> {
    return Axios.get(`${API._DEPLOY_SERVICE}/models`, { withCredentials: true })
      .then((result) => result.data)
      .catch((err) => API.handleError(err));
  }

  static async createModel(model: Model): Promise<ModelResponse> {
    return Axios.post(`${API._DEPLOY_SERVICE}/models`, model, { withCredentials: true })
      .then((result) => result.data)
      .catch((err) => API.handleError(err));
  }

  static getModelById(id: string): Promise<Model> {
    return Axios.get(`${API._DEPLOY_SERVICE}/models/${id}`, { withCredentials: true })
      .then((result) => result.data)
      .catch((err) => API.handleError(err));
  }

  static patchModelById(id: string, data: Partial<Model>): Promise<{ success: boolean }> {
    return Axios.patch(`${API._DEPLOY_SERVICE}/models/${id}`, data, { withCredentials: true })
      .then((result) => result.data)
      .catch((err) => API.handleError(err));
  }

  static deployModel(id: string, envs: EnvType[]): Promise<ModelResponse> {
    return Axios.post(
      `${API._DEPLOY_SERVICE}/models/${id}/deploy`,
      {
        envs
      },
      { withCredentials: true }
    )
      .then((result) => result.data)
      .catch((err) => API.handleError(err));
  }

  static deploySubModel(version: string, type: string, env: EnvType): Promise<SubModelResponse> {
    return Axios.post(
      `${API._DEPLOY_SERVICE}/sub-models/deploy`,
      {
        version,
        type,
        env
      },
      { withCredentials: true }
    )
      .then((result) => result.data)
      .catch((err) => API.handleError(err));
  }

  static getLatestDeployLog(type: AnalyticType | string, env: EnvType): Promise<DeployLog> {
    return Axios.get(`${API._DEPLOY_SERVICE}/deploy-logs/latest`, {
      params: {
        type: type,
        env: env
      },
      withCredentials: true
    })
      .then((result) => result.data)
      .catch((err) => {
        if (err?.response?.status !== 404) {
          return API.handleError(err);
        }
      });
  }

  static buildModel(id: string): Promise<ModelResponse> {
    return Axios.post(`${API._DEPLOY_SERVICE}/models/${id}/build`, {}, { withCredentials: true })
      .then((result) => result.data)
      .catch((err) => API.handleError(err));
  }

  static retrainModel(id: string): Promise<ModelResponse> {
    return Axios.post(`${API._DEPLOY_SERVICE}/models/${id}/retrain`, {}, { withCredentials: true })
      .then((result) => result.data)
      .catch((err) => API.handleError(err));
  }

  static BuildModelsAndRunRegressionTests(
    models: string[],
    analyticsToTest: string[],
    daysToClear: number
  ): Promise<void> {
    return Axios.get(
      `${API._DEPLOY_SERVICE}/build-and-test?models=${models.join(',')}&analytics=${analyticsToTest.join(
        ','
      )}&clear_surveys=${daysToClear}`,
      {
        withCredentials: true
      }
    )
      .then((result) => result.data)
      .catch((err) => API.handleError(err));
  }

  static async getAnalytic(runId: string, tileId: string, resultType: string): Promise<FeatureCollection> {
    const suffix = resultType;
    const storageFileName = `${tileId}_${suffix}`;
    try {
      // TODO: check cache issue
      const { data } = await Axios.get(
        `${API.STORAGE_BASE_URL}/${API.BENCHMARK_BUCKET}/${runId}/output/${storageFileName}`
      );
      if (data.grid) {
        const features = [];
        Object.keys(data.grid).forEach((key) => {
          const [x, y] = key.split('_');
          const tile = [parseInt(x), parseInt(y), 26];
          features.push(
            feature(tileToGeoJSON(tile), {
              color: 'rgb(255, 255, 0)'
            })
          );
        });
        return featureCollection(features);
      }
      return data;
    } catch (error) {
      API.handleError(error as AxiosError, `Could not load from GCP storage ${storageFileName}`);
    }
  }

  static async getTrainingSets(analyticType?: AnalyticType): Promise<TrainingSet[]> {
    return Axios.get(`${API._DEPLOY_SERVICE}/training-sets`, { withCredentials: true, params: { analyticType } })
      .then((result) => result.data)
      .catch((err) => API.handleError(err));
  }

  // load latest test tile and create new list if it is unique
  static syncTrainingSets(analyticType: AnalyticType): Promise<TrainingSetResponse> {
    return Axios.post(
      `${API._DEPLOY_SERVICE}/training-sets/sync`,
      { analyticType: analyticType },
      { withCredentials: true }
    )
      .then((result) => result.data)
      .catch((err) => API.handleError(err));
  }

  static refreshSession(): Promise<RefreshSessionResponse> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/refresh-session`, { withCredentials: true }).then(
      (result) => result.data
    );
  }

  static getMenu(): Promise<Country[]> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/menu?deploy_manager=true`, { withCredentials: true })
      .then((result) => result.data || [])
      .catch(API.handleError);
  }

  static updateUnit(data: Partial<Unit>): Promise<Country[]> {
    return Axios.patch(`${API._PRODUCT_MANAGER_URL}/unit`, data, { withCredentials: true })
      .then((result) => result.data || [])
      .catch(API.handleError);
  }

  static handleError(serviceError: AxiosError<any>, clientError = 'something went wrong'): void {
    let serviceErrorMsg = '';
    if (serviceError.response) {
      serviceErrorMsg = `${serviceError?.response?.data?.message}, statusCode: ${serviceError.response.status}`;
    } else {
      serviceErrorMsg = serviceError.message;
    }

    message.error((createElement) => {
      return createElement('span', [
        createElement('span', clientError),
        createElement('br'),
        createElement('span', serviceErrorMsg)
      ]);
    }, 5);

    throw serviceError;
  }
}

export default API;
