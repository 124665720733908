import { ActionTree, Module, MutationTree } from 'vuex';

import { RepoState, Repo } from '@/interfaces/repo';
import { Filter } from '@/interfaces/filter';
import { RootState } from '@/interfaces/root-state';
import GithubApi from '@/services/github-api';

export const state: RepoState = {
  repos: [],
  filteredRepos: [],
  filter: {
    search: ''
  }
};

export const actions: ActionTree<RepoState, RootState> = {
  async loadRepos({ commit, dispatch, state }) {
    dispatch('showGlobalLoader', true, { root: true });
    const repos = await GithubApi.getAllData();
    commit('setRepos', repos);
    dispatch('filterRepos', state.filter);
    dispatch('showGlobalLoader', false, { root: true });
  },

  filterRepos({ commit, dispatch }, filter: Filter) {
    dispatch('showGlobalLoader', true, { root: true });
    commit('filterRepos', filter);
    dispatch('showGlobalLoader', false, { root: true });
  }
};

export const mutations: MutationTree<RepoState> = {
  setRepos: (state, repos: Repo[]) => {
    state.repos = repos;
  },
  filterRepos: (state, filter: Filter) => {
    if (!filter.search) {
      state.filteredRepos = state.repos;
    }
    state.filter = filter;
    state.filteredRepos = state.repos.filter((repo) => {
      return repo.name.toLowerCase().includes(filter.search.toLowerCase());
    });
  }
};

export const repo: Module<RepoState, RootState> = { namespaced: true, state, actions, mutations };
