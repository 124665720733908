
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ErrorLogBody extends Vue {
  @Prop(String) text: string;

  constructor() {
    super();
  }
}
