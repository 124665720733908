export enum AnalyticType {
  SOWING = 'sowing',
  WEEDS = 'weeds',
  SAT_WEEDS = 'sat-weeds',
  SAT_NEMATODES = 'sat-nematodes',
  SAT_HARVEST = 'sat-harvest',
  SAT_ANOMALY = 'sat-anomaly',
  SAT_SUGAR = 'sat-sugar',
  FLOWERING = 'flowering',
  LAND_USE = 'land-use',
  DEFORESTATION = 'deforestation'
}
