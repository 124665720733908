export function jiraLink(message: string): string {
  const match = message.match(/SW-[0-9]+/);
  if (match) {
    message = message.replace(
      match[0],
      `<a href="https://gamaya.atlassian.net/browse/${match[0]}" target="_blank">${match[0]}</a>`
    );
  }
  return message;
}
