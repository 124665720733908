var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"map-container"},[_c('Mapbox',{attrs:{"access-token":"pk.eyJ1Ijoia2lycnVraXJydSIsImEiOiJjazJhMmJ6anMxMGh5M21tczJ6NTEwaW4yIn0.irfuud6XtRKV6K7hSv-bkQ","map-options":{
      container: 'map',
      style: {
        version: 8,
        sources: {},
        zoom: 19,
        layers: [],
        id: 'gamaya'
      },
      tileSize: 256,
      preserveDrawingBuffer: true,
      pitchWithRotate: false
    },"scale-control":{ show: true },"nav-control":{ show: true, position: 'bottom-left' }},on:{"map-load":_vm.onMapLoaded}}),(!_vm.selectedTile)?_c('div',{staticClass:"no-data"},[_vm._v("No tile selected")]):_vm._e(),(_vm.selectedTile)?_c('div',{staticClass:"map-overlay"},[_c('div',[_vm._m(0),_c('div',[_c('a-checkbox',{on:{"change":_vm.toggleModelRaw},model:{value:(_vm.isShowModelRaw),callback:function ($$v) {_vm.isShowModelRaw=$$v},expression:"isShowModelRaw"}},[_vm._v("Show raw model")])],1),_c('div',[(_vm.analyticType === _vm.AnalyticTypeEnum.SOWING)?_c('a-checkbox',{on:{"change":_vm.toggleModelClipped},model:{value:(_vm.isShowModelClipped),callback:function ($$v) {_vm.isShowModelClipped=$$v},expression:"isShowModelClipped"}},[_vm._v("Show clipped model")]):_vm._e()],1),_c('div',[_c('a-checkbox',{on:{"change":_vm.toggleModelAnalytic},model:{value:(_vm.isShowModelAnalytic),callback:function ($$v) {_vm.isShowModelAnalytic=$$v},expression:"isShowModelAnalytic"}},[_vm._v("Show final model")])],1),_c('div',[_c('a-checkbox',{on:{"change":_vm.toggleCuratedAnalytic},model:{value:(_vm.isShowCuratedAnalytic),callback:function ($$v) {_vm.isShowCuratedAnalytic=$$v},expression:"isShowCuratedAnalytic"}},[_vm._v("Show curated")])],1),(_vm.analyticType === _vm.AnalyticTypeEnum.WEEDS)?_c('div',[_c('a-checkbox',{on:{"change":_vm.toggleLabelledRaster},model:{value:(_vm.isShowLabelledRaster),callback:function ($$v) {_vm.isShowLabelledRaster=$$v},expression:"isShowLabelledRaster"}},[_vm._v("Labelled Raster")])],1):_vm._e(),_c('div',[_c('a-checkbox',{on:{"change":_vm.toggleAnalyticRaster},model:{value:(_vm.isShowAnalyticRaster),callback:function ($$v) {_vm.isShowAnalyticRaster=$$v},expression:"isShowAnalyticRaster"}},[_vm._v("Analytic Raster")])],1)])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('b',[_vm._v("Analytic")])])
}]

export { render, staticRenderFns }