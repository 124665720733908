import { message } from 'ant-design-vue';

import { Octokit } from '@octokit/rest';
import { Repository } from '@/enums/repos';
import Constants from './constants';

const octokit = new Octokit({
  auth: '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'
});

const org = 'GamayaSpectral';
const repos: any[] = [
  { name: Repository.ORB2, type: 'ui', lang: 'vue' },
  { name: Repository.VTSERVER, type: 'backend', lang: 'node' },
  { name: Repository.AUTH_SERVICE, type: 'ui', lang: 'vue' },
  { name: Repository.PRODUCT_MANAGER, type: 'backend', lang: 'go' },

  { name: Repository.ANALYTIC_LAUNCHER, type: 'backend', lang: 'go' },
  { name: Repository.SATELLITE_SERVICE, type: 'backend', lang: 'go' },

  { name: Repository.SAT_EXPLORER, type: 'ui', lang: 'vue' },
  { name: Repository.CURATION_SERVICE, type: 'backend', lang: 'go' },

  { name: Repository.SHAPE_MANAGER, type: 'ui', lang: 'vue' },
  { name: Repository.CLOUDS_CURATION, type: 'ui', lang: 'vue' },

  { name: Repository.DEPLOY_MANAGER, type: 'ui', lang: 'vue' },
  { name: Repository.DEPLOY_SERVICE, type: 'backend', lang: 'go' },
  { name: Repository.BENCHMARK_MODELS, type: 'backend', lang: 'go' },

  { name: Repository.DRONE_SERVICE, type: 'backend', lang: 'go' },
  { name: Repository.WEEDS_CURATION, type: 'ui', lang: 'vue' },
  { name: Repository.CURATION_OPERATOR_VIEW, type: 'ui', lang: 'vue' },
  { name: Repository.CURATION_CURATOR_VIEW, type: 'ui', lang: 'vue' },

  { name: Repository.LABELLING, type: 'ui', lang: 'vue' },
  { name: Repository.LABELLING_SERVICE, type: 'backend', lang: 'go' },

  { name: Repository.DASHBOARD_VIEW, type: 'ui', lang: 'vue' },
  { name: Repository.DASHBOARD_SERVICE, type: 'backend', lang: 'go' },

  { name: Repository.ALIGNMENT_CLIENT, type: 'ui', lang: 'vue' },
  { name: Repository.ALIGNMENT_SERVICE, type: 'backend', lang: 'go' },

  { name: Repository.UPLOADER, type: 'app', lang: 'electron' },

  { name: Repository.HARVEST_PIXEL_BASED, type: 'ai', lang: 'jupyter notebook' },
  { name: Repository.NDVI_FUSION_ANALYTIC, type: 'ai', lang: 'jupyter notebook' },
  { name: Repository.SUGARCONTENT_YIELD_BRAZIL, type: 'ai', lang: 'jupyter notebook' },
  { name: Repository.GAMAYAPY, type: 'ai', lang: 'python' },
  { name: Repository.NEMATODE, type: 'ai', lang: 'python' },
  { name: Repository.SOWING_SUGARCANE, type: 'ai', lang: 'jupyter notebook' },
  { name: Repository.LAND_USE, type:'ai', lang:'jupyter notebook'}
];

repos.forEach((repo) => {
  repo.prs = [];
  repo.dev = {};
  repo.preprod = { diff: { commits: [] } };
  repo.prod = { diff: { commits: [] } };
});

class GithubApi {
  static async getAllData(): Promise<any[]> {
    const allRequests = [];
    for (let i = 0; i < repos.length; i++) {
      const repo = repos[i];
      const calls = [];
      // get PRs info
      calls.push(octokit.pulls.list({ owner: org, repo: repo.name }));
      // get dev commits
      calls.push(
        octokit.repos.getBranch({
          owner: org,
          repo: repo.name,
          branch: 'master'
        })
      );
      // get tags for preprod
      calls.push(
        octokit.repos.listTags({
          owner: org,
          repo: repo.name,
          per_page: 1,
          page: 1
        })
      );
      // get last release for prod
      // TODO: if repo doesn't have last release, handle error to ignore
      calls.push(
        octokit.repos.getLatestRelease({
          owner: org,
          repo: repo.name,
          branch: 'master'
        })
      );
      // get releases for prod
      if (Constants.DS_REPO_MULTIDOCKER.includes(repo.name)) {
        calls.push(
          octokit.repos.listReleases({
            owner: org,
            repo: repo.name,
            branch: 'master',
            per_page: 100
          })
        );
      }
      allRequests.push(Promise.all(calls));
    }

    const data = await Promise.all(allRequests).catch(GithubApi.handleError);
    const diffRequestsProd = [];
    const diffRequestsPreprod = [];
    for (let i = 0; i < repos.length; i++) {
      const repo = repos[i];
      repo.prs = data[i][0].data;
      repo.dev = data[i][1].data;
      repo.preprod = data[i][2].data[0];
      const lastTag = repo.preprod.name.split('.');
      const lastDigit = +lastTag[2];
      repo.preprod.newTag = lastTag[0] + '.' + lastTag[1] + '.' + (lastDigit + 1);
      repo.prod = data[i][3].data;
      repo.prod.preprod = repo.preprod;

      if (Constants.DS_REPO_MULTIDOCKER.includes(repo.name)) {
        repo.prod.releases = data[i][4].data;
      }

      // get diffs
      diffRequestsProd.push(
        octokit.repos.compareCommits({
          owner: org,
          repo: repo.name,
          base: repo.prod.tag_name,
          head: repo.preprod.name
        })
      );
      diffRequestsPreprod.push(
        octokit.repos.compareCommits({
          owner: org,
          repo: repo.name,
          base: repo.preprod.name,
          head: 'master'
        })
      );
    }

    let diffData = await Promise.all(diffRequestsProd);
    for (let i = 0; i < repos.length; i++) {
      const repo = repos[i];
      repo.prod.diff = diffData[i].data;
    }
    diffData = await Promise.all(diffRequestsPreprod);
    for (let i = 0; i < repos.length; i++) {
      const repo = repos[i];
      repo.preprod.diff = diffData[i].data;
    }

    // console.log(repos);
    return repos;
  }

  static async createTag(repo: any): Promise<any> {
    const createdTag = await octokit.git.createTag({
      owner: org,
      repo: repo.name,
      tag: repo.preprod.newTag,
      message: repo.preprod.newTag,
      object: repo.dev.commit.sha,
      type: 'commit'
    });

    if (createdTag.status !== 201) {
      message.error(`Could not create tag. Received ${createdTag.status} from API`);
      return;
    }

    return octokit.git
      .createRef({
        owner: org,
        repo: repo.name,
        ref: 'refs/tags/' + repo.preprod.newTag,
        sha: createdTag.data.sha
      })
      .then((result) => {
        message.info('Created. Reload the page to see changes');
      })
      .catch((err) => GithubApi.handleError(err));
  }

  static handleError(serviceError: any, clientError = 'something went wrong'): void {
    let serviceErrorMsg = '';
    if (serviceError.response) {
      serviceErrorMsg = `${serviceError.message}, statusCode: ${serviceError.response.status}`;
    } else {
      serviceErrorMsg = serviceError.message;
    }

    message.error((createElement) => {
      return createElement('span', [
        createElement('span', clientError),
        createElement('br'),
        createElement('span', serviceErrorMsg)
      ]);
    }, 5);

    throw serviceError;
  }
}

export default GithubApi;
