import { render, staticRenderFns } from "./Mapping.vue?vue&type=template&id=e838d4a0&scoped=true&"
import script from "./Mapping.vue?vue&type=script&lang=ts&"
export * from "./Mapping.vue?vue&type=script&lang=ts&"
import style0 from "./Mapping.vue?vue&type=style&index=0&id=e838d4a0&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e838d4a0",
  null
  
)

export default component.exports