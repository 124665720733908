import { AnalyticType } from './analytic-type';
import { Filter } from '@/interfaces/filter';

export enum RunStatusType {
  Initial = 'Initial',
  InProgress = 'In progress',
  Failed = 'Failed',
  Canceled = 'Canceled',
  Finished = 'Finished'
}

export interface Tile {
  id: string;
  metrics?: { [key: string]: string | number };
}

export interface Run {
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  modelVersion?: string;
  analyticType: AnalyticType;
  testSet?: Tile[];
  aggregatedMetrics?: { [key: string]: string | number };
  testStatus?: RunStatusType;
  testProgress?: number;
  testSetId?: string;
  notes?: string;
}

export interface RunState {
  data: Run[];
  filteredData: Run[];
  filter: Filter;
}

export interface RunResponse {
  success: boolean;
  status?: string;
  message?: string;
  id?: string;
}
