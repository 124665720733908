
import { Component, Vue } from 'vue-property-decorator';
import { RunStatusType } from '@/interfaces/run';
import { FormModel } from 'ant-design-vue';
import API from '@/services/rest-api';
import { Run } from '@/interfaces/run';
import { PageName } from '@/enums/page-name';
import { getUnitByMetric } from '@/services/unit';
import { AnalyticType } from '@/interfaces/analytic-type';
import EditableCell from '@/components/EditableCell.vue';

export interface MenuEvent extends Event {
  key: string;
}

const columns = [
  {
    title: 'Name',
    dataIndex: 'createdAt',
    key: 'createdAt',
    scopedSlots: { customRender: 'name' },
    width: 200
  },
  {
    title: 'Model version',
    dataIndex: 'modelVersion',
    key: 'modelVersion',
    scopedSlots: { customRender: 'modelVersion' },
    width: 150
  },
  {
    title: 'Analytic',
    dataIndex: 'analyticType',
    key: 'analyticType',
    width: 100,
    filters: [
      { text: AnalyticType.SOWING, value: AnalyticType.SOWING },
      { text: AnalyticType.WEEDS, value: AnalyticType.WEEDS },
      { text: AnalyticType.SAT_WEEDS, value: AnalyticType.SAT_WEEDS },
      { text: AnalyticType.SAT_NEMATODES, value: AnalyticType.SAT_NEMATODES },
      { text: AnalyticType.SAT_ANOMALY, value: AnalyticType.SAT_ANOMALY },
      { text: AnalyticType.SAT_HARVEST, value: AnalyticType.SAT_HARVEST },
      { text: AnalyticType.SAT_SUGAR, value: AnalyticType.SAT_SUGAR },
      { text: AnalyticType.LAND_USE, value: AnalyticType.LAND_USE}
    ],
    onFilter: (value, record) => record.analyticType === value
  },
  {
    title: 'Performance',
    scopedSlots: { customRender: 'performance' },
    dataIndex: 'aggregatedMetrics',
    key: 'aggregatedMetrics'
  },
  {
    title: 'Test set',
    dataIndex: 'testSet',
    key: 'testSet',
    scopedSlots: { customRender: 'testSet' },
    width: 120
  },
  {
    title: 'Test status',
    dataIndex: 'testStatus',
    key: 'testStatus',
    scopedSlots: { customRender: 'testStatus' },
    width: 150
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    scopedSlots: { customRender: 'notes' },
    width: 200
  },
  {
    title: 'Actions',
    key: 'actions',
    scopedSlots: { customRender: 'actions' },
    width: 200
  }
];

export interface Performance {
  [key: string]: number | string;
}

@Component({
  components: {
    EditableCell
  }
})
export default class BenchmarkRun extends Vue {
  pageName = PageName;
  runStatusType = RunStatusType;
  modelVersionsPerAnalyticType: string[] = [];
  testSets = [];
  isTestSetsLoading = false;

  private columns = columns;
  private isModaVisible = false;
  private analyticType = AnalyticType;
  private form = {
    analyticType: AnalyticType.SOWING,
    modelVersion: undefined,
    testSetId: undefined
  };

  isSubmitBtnEnabled = true;

  rules = {
    analyticType: [{ required: true, message: 'Analytic Type is required', trigger: 'change' }],
    modelVersion: [{ required: true, message: 'Model version is required', trigger: ['change', 'blur'] }],
    testSetId: [{ required: true, message: 'Test set is required', trigger: ['change', 'blur'] }]
  };

  get dataSource(): Run[] {
    return this.$store.state.run.filteredData;
  }

  get runAnalytics(): AnalyticType[] {
    return Object.keys(AnalyticType).map((key) => {
      return AnalyticType[key];
    });
  }

  getModelVersionsByAnalytic(value: AnalyticType): string[] {
    return this.$store.getters['model/versionsList'](value);
  }

  async mounted(): Promise<void> {
    this.$store.dispatch('run/loadData');
    await this.$store.dispatch('model/loadData');
    this.modelVersionsPerAnalyticType = this.getModelVersionsByAnalytic(this.form.analyticType);
  }

  onSearch(value: string): void {
    this.$store.dispatch('run/filterData', { search: value });
  }

  get tableScrollOpt(): { y: number } {
    const headerHeight = 180;
    return { y: window.innerHeight - headerHeight };
  }

  resetForm(): void {
    this.form = {
      analyticType: AnalyticType.SOWING,
      modelVersion: undefined,
      testSetId: undefined
    };
  }

  handleSubmit(e: Event): void {
    e.preventDefault();

    this.isSubmitBtnEnabled = false;

    (this.$refs.newRunForm as FormModel).validate(async (isValid) => {
      if (isValid) {
        try {
          await API.createRun({
            analyticType: this.form.analyticType,
            modelVersion: this.form.modelVersion,
            testSetId: this.form.testSetId
          });

          this.isModaVisible = false;
          this.resetForm();
          this.isSubmitBtnEnabled = true;
          this.$store.dispatch('run/loadData');
        } catch (error) {
          this.isSubmitBtnEnabled = true;
        }
      }
    });
  }

  async handleAction(id: string, e: MenuEvent): Promise<void> {
    this.$store.dispatch('showGlobalLoader', true);
    switch (e.key) {
      case 'benchmark':
        await API.benchmarkRun(id);
        break;
      default:
        break;
    }
    this.$store.dispatch('showGlobalLoader', false);
  }

  showModal(): void {
    this.isModaVisible = true;
    this.loadTestSets();
  }

  getUnit(param: string): string {
    return getUnitByMetric(param);
  }

  handleAnalyticTypeChange(value: AnalyticType): void {
    // deselect model version because the model version diffent for each analytic type
    this.form.modelVersion = undefined;
    this.form.testSetId = undefined;

    this.modelVersionsPerAnalyticType = this.getModelVersionsByAnalytic(value);
    this.loadTestSets(); // load testSets for particular analytic
  }

  async loadTestSets(): Promise<void> {
    this.testSets = await API.getTestSets(this.form.analyticType);
  }

  async syncTestSets(): Promise<void> {
    try {
      this.isTestSetsLoading = true;
      await API.syncTestSets(this.form.analyticType);
      await this.loadTestSets();
    } finally {
      this.isTestSetsLoading = false;
    }
  }

  getPercents(performance: Performance): Performance {
    var filtered = {};
    for (let key in performance) {
      if (key.includes('Accuracy') || key.includes('iouPercent')) {
        filtered[key] = performance[key];
      }
    }
    return filtered;
  }

  getOthers(performance: Performance): Performance {
    var filtered = {};
    for (let key in performance) {
      if (!key.includes('Accuracy') && !key.includes('iouPercent')) {
        filtered[key] = performance[key];
      }
    }
    return filtered;
  }

  getFormatedDate(ISODate: string): string {
    return ISODate.slice(0, 19);
  }

  handleNotesChange(model: Run, value: string): void {
    API.updateRunById(model.id, { notes: value });
  }
}
