export enum EnvType {
  DEV = 'dev',
  PREPROD = 'preprod',
  PROD = 'prod'
}

export interface ValidationResponse {
  extra: Record<string, string>;
  missing: Record<string, string>;
}
